import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import $ from "jquery";
import HeaderBar from "./components/HeaderBar";
import Landing from "./pages/landing";
import { ChakraProvider } from "@chakra-ui/react";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  getAppData() {
    $.ajax({
      url: "./data.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ data: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getAppData();
  }

  render() {
    return (
      <ChakraProvider>
        <Router>
          <div style={{ alignItems: "center" }}>
            <HeaderBar />
            <Routes>
              <Route path="/" element={<Landing data={this.state.data} />} />
            </Routes>
          </div>
        </Router>
      </ChakraProvider>
    );
  }
}

export default App;
