import React, { Component } from "react";
import {
  Highlight,
  Heading,
  Input,
  Textarea,
  Stack,
  Button,
  Divider,
} from "@chakra-ui/react";
import "./landing.css";

class Landing extends Component {
  render() {
    return (
      <main className="flex min-h-screen max-w-screen-xxl flex-col items-center justify-between">
        <div className="items-center">
          <div className="flex items-center justify-center">
            <img src="/ozc_banner.png" alt="banner of Oz Clothings" width={500} />
          </div>
        </div>

        <div className="justify-between max-w-screen-xl mx-auto mb-10 mt-10 items-center text-center max-md:w-10/12 w-3/5">
          <Heading lineHeight="tall" fontFamily="mono">
            <Highlight
              query="life"
              styles={{ px: "3", py: "1", rounded: "full", bg: "blue.200" }}
            >
              BRING YOUR DESIGNS TO LIFE
            </Highlight>
          </Heading>
          <Heading
            className="sub-header"
            as="h2"
            lineHeight="tall"
            fontFamily="mono"
            size="lg"
          >
            Excellence. Delivery. Quality.
          </Heading>
        </div>

        <div className="flex flex-col md:flex-row justify-between max-md:w-10/12 max-w-screen-xl mx-auto items-center w-3/5">
          <div className="md:w-6/12 flex-shrink-0 relative">
            <p className="sub-header text-center text-4xl font-bold">Welcome</p>
            <div className="w-2/3 mt-4 text-300 font-bold">
              <p>Dear our valued customers,</p>
            </div>
            <div className="w-full mt-4 text-200 max-md:text-xs max-md:w-full">
              <p>
                We understand that it is the greatest feeling bringing your
                designs to reality.
              </p>
            </div>
          </div>
          <div className="md:w-6/12 mt-16 md:mt-0 md:mr-12 lg:mr-16">
            <img
              src="/images/pexels-lucas-pezeta-2529375.jpg"
              alt="A warm welcome"
              width={700}
              height={500}
              className="rounded-lg object-cover object-center"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between max-md:w-10/12 max-w-screen-xl mx-auto items-center w-3/5">
          <div className="md:w-6/12 mt-16 md:mt-0 md:mr-12 lg:mr-16">
            <img
              src="/images/pexels-cottonbro-studio-4614199.jpg"
              alt="A sewing machine"
              width={700}
              height={500}
              className="rounded-lg object-cover object-center"
            />
          </div>
          <div className="md:w-6/12 flex-shrink-0 relative max-md:order-first md:order-last">
            <p className="sub-header text-center text-4xl font-bold">
              Manufacturing
            </p>
            <div className="w-full mt-4 text-200 max-md:text-xs max-md:w-full">
              <p>
                We have been supplying batch orders to America, Korea, EU, and
                South East Asia markets since 2003. As a proven supplier, we
                employ top class quality control and logistics.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between max-md:w-10/12 max-w-screen-xl mx-auto items-center w-3/5">
          <div className="md:w-6/12 flex-shrink-0 relative">
            <p className="sub-header text-center text-4xl font-bold">
              Logistics
            </p>
            <div className="w-full mt-4 text-200 max-md:text-xs max-md:w-full">
              <p>
                We take care of the logistic hustle so that you can focus on the
                creation.
              </p>
            </div>
          </div>
          <div className="md:w-6/12 mt-16 md:mt-0 md:mr-12 lg:mr-16">
            <img
              src="/images/pexels-julius-silver-753331.jpg"
              alt="Shipping cargo of cloths"
              width={700}
              height={500}
              className="rounded-lg object-cover object-center"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between max-md:w-10/12 max-w-screen-xl mx-auto items-center w-3/5">
          <div className="md:w-6/12 mt-16 md:mt-0 md:mr-12 lg:mr-16">
            <img
              src="/images/thanh_cong_uniform.png"
              alt="Uniforms production"
              width={700}
              height={500}
              className="rounded-lg object-cover object-center"
            />
          </div>
          <div className="md:w-6/12 flex-shrink-0 relative max-md:order-first md:order-last">
            <p className="sub-header text-center text-4xl font-bold">
              Uniforms
            </p>
            <div className="w-full mt-4 text-200 max-md:text-xs max-md:w-full">
              <p>
                We specialize in creating high-quality and stylish uniforms for
                various industries and organizations. With our attention to
                detail, top-notch craftsmanship, and commitment to customer
                satisfaction, we ensure that each uniform reflects
                professionalism, comfort, and durability. Our products are student uniforms, jerseys, etc.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between max-md:w-10/12 max-w-screen-xl mx-auto items-center w-3/5">
          <div className="md:w-6/12 flex-shrink-0 relative">
            <p className="sub-header text-center text-4xl font-bold">Fashion</p>
            <div className="w-full mt-4 text-200 max-md:text-xs max-md:w-full">
              <p>
                At our fashion manufacturing company, we pride ourselves on
                delivering high-quality products and exceptional service. With
                years of experience and a commitment to excellence, we
                specialize in producing a wide range of fashionable garments.
              </p>
            </div>
          </div>
          <div className="md:w-6/12 mt-16 md:mt-0 md:mr-12 lg:mr-16">
            <img
              src="/images/pexels-monica-turlui-7137418.jpg"
              alt="Shipping cargo of cloths"
              width={700}
              height={500}
              className="rounded-lg object-cover object-center"
            />
          </div>
        </div>

        <div className="md:w-6/12 flex-shrink-0 mt-4 relative w-3/5">
          <p className="sub-header text-center text-4xl font-bold">
            Our partners
          </p>
          <div className="items-center justify-center mt-4 text-200 max-md:text-xs max-md:w-full">
            <Stack
              className="items-center justify-center"
              direction="row"
              spacing={4}
            >
              <a href="https://www.elisedesign.com.au" target="_blank" rel="noreferrer">
                <div
                  className="mask overlay"
                  style={{ backgroundColor: "rgba(57, 192, 237, 0.2)" }}
                >
                  <img
                    src="images/partners/EliseDesign.png"
                    className="img-fluid"
                    alt="Logo of Elise Design"
                    width={100}
                    height={100}
                  />
                </div>
              </a>
              <a href="https://www.thanhcongcorp.com/" target="_blank" rel="noreferrer">
                <div
                  className="mask overlay"
                  style={{ backgroundColor: "rgba(57, 192, 237, 0.2)" }}
                >
                  <img
                    src="images/partners/ThanhCongCorp.png"
                    className="img-fluid"
                    alt="Logo of Elise Design"
                    width={100}
                    height={100}
                  />
                </div>
              </a>
            </Stack>
          </div>
        </div>

        <div className="md:w-6/12 flex-shrink-0 mt-4 relative w-3/5">
          <p className="sub-header text-center text-4xl font-bold">
            Contact Us
          </p>
          <div className="mt-4 text-200 max-md:text-xs max-md:w-full">
            <form>
              <div>
                <Stack direction="column" spacing={4}>
                  <Stack direction="row" spacing={4}>
                    <Input placeholder="Email" isRequired={true} />
                    <Input placeholder="Name" isRequired={true} />
                  </Stack>
                  <Textarea placeholder="Your message" isRequired={true} />
                  <Button>Submit</Button>
                </Stack>
              </div>
            </form>
          </div>
        </div>

        <Divider className="mt-2" orientation="horizontal" />

        <footer className="text-center py-4">
          <p className="text-sm text-gray-500">&copy; Oz Clothings 2024</p>
        </footer>
      </main>
    );
  }
}

export default Landing;
