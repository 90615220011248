import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import { Link } from "react-router-dom";

function HeaderBar() {
  return (
    <Navbar className="items-center justify-center md:mx-80 max-md:w-full md:w-10" position="sticky" shouldHideOnScroll>
      <NavbarBrand>
        <p className="font-bold text-inherit">OZ</p>
      </NavbarBrand>
      <NavbarContent className="hidden flex gap-4" justify="center">
        <NavbarItem>
          <Link color="foreground" to="/">
            Home
          </Link>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}

export default HeaderBar;
